/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { GetFormById } from "../../services/Api/formApi";
import { Table } from "antd";
import { Card } from "primereact/card";
import moment from "moment";

const ViewForm = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState([]);
	const [loading, setLoading] = useState(true);

	// Fetch user details by ID
	useLayoutEffect(() => {
		setLoading(true);
		GetFormById(id)
			.then((res) => {
				setUserData([res.data.data]); // Ensure data is wrapped in an array for mapping
				setLoading(false);
			})
			.catch((err) => {
				console.error("Error fetching form details:", err);
				setLoading(false);
			});
	}, [id]);

	// Custom mapping of headers to the appropriate data keys
	const headers = [
		{ label: "Name", key: "name" },
		{ label: "Date", key: "data_s_date" },
		{ label: "Phone (Cell)", key: "phone_contact_cell" },
		{ label: "Phone (Home)", key: "phone_contact_home" },
		{ label: "Phone (Other)", key: "phone_contact_other" },
		{ label: "Email Address", key: "email_address" },
		{ label: "Origin Address", key: "origin_address" },
		{ label: "Extra Origin Address", key: "extra_origin_address" },
		{ label: "Storage Pickup", key: "storage_pickup" },
		{ label: "Delivery Address", key: "delivery_address" },
		{ label: "Extra Delivery Address", key: "extra_delivery_address" },
		{ label: "Storage Delivery", key: "storage_delivery" },
		{
			label: "Tractor Trailer Parking at Origin",
			key: "tractor_trailer_parking_at_origin",
		},
		{
			label: "Tractor Trailer Parking at Delivery",
			key: "tractor_trailer_parking_at_delivery",
		},
		{
			label: "Gated Community Name (Origin)",
			key: "gated_community_name_code_at_origin",
		},
		{
			label: "Gated Community Name (Destination)",
			key: "gated_community_name_code_at_destination",
		},
		{
			label: "Elevator at Origin Available",
			key: "elevator_origin_available",
		},
		{
			label: "Elevator at Destination Available",
			key: "elevator_destination_available",
		},
		{
			label: "Stairs at Origin Available",
			key: "stairs_origin_available",
		},
		{
			label: "Stairs at Destination Available",
			key: "stairs_destination_available",
		},
		{ label: "International Shipment", key: "international_shipment" },
	];

	// Transform data into a vertical layout
	const transformedData = headers.map((header, index) => ({
		key: index,
		header: header.label, // The header text
		values: userData.map((user) => {
			if (header.key === "data_s_date")
				return moment(user?.date || "").format("YYYY-MM-DD") || "N/A"; // Map and format date
			return user?.[header.key] || "N/A"; // Map data key or default to "N/A"
		}),
	}));

	// Vertical columns for form details
	const verticalColumns = [
		{
			title: "Field Name",
			dataIndex: "header",
			key: "header",
			align: "left",
			render: (text) => <strong>{text}</strong>,
		},
		{
			title: "Details",
			dataIndex: "values",
			key: "values",
			render: (values) =>
				values.map((value, idx) => (
					<div key={idx} style={{ marginBottom: "5px" }}>
						{value || "--"}
					</div>
				)),
		},
	];

	// List of room types
	const roomTypes = [
		"LIVINGROOM",
		"BEDROOM",
		"CHILDNURSERY",
		"OFFICESTUDY",
		"DININGROOM",
		"KITCHEN",
		"LAUNDRYROOM",
		"MISCELLANEOUS",
	];

	// Render tables for each room type
	const renderTables = () => {
		return roomTypes.map((room) => {
			// Filter inventory for the specific room type
			const roomData = userData[0]?.user_inventory?.filter(
				(item) => item.room_type === room
			);

			// Define columns for the table
			const roomColumns = [
				{
					title: "Item Name",
					dataIndex: "item_name",
					key: "item_name",
				},
				{
					title: "Quantity",
					dataIndex: "quantity",
					key: "quantity",
				},
				{
					title: "Dimensions",
					dataIndex: "dimensions",
					key: "dimensions",
					render: (dimensions) => dimensions || "--", // Display '--' if dimension is missing
				},
				{
					title: "By Owner",
					dataIndex: "by_owner",
					key: "by_owner",
					render: (byOwner) => byOwner || "--", // Display '--' if byOwner is missing
				},
				{
					title: "By Carrier",
					dataIndex: "by_carrier",
					key: "by_carrier",
					render: (byCarrier) => byCarrier || "--", // Display '--' if byCarrier is missing
				},
				{
					title: "Notes",
					dataIndex: "notes",
					key: "notes",
					render: (notes) => notes || "--", // Display '--' if notes is missing
				},
			];

			// Only render a table if there is data for this room type
			return roomData?.length > 0 ? (
				<div key={room} style={{ marginBottom: "20px" }}>
					<h4>{room.replace(/_/g, " ")}</h4>
					<Table
						columns={roomColumns}
						dataSource={roomData}
						rowKey={(record) => record.item_name + record.room_type} // Unique key
						pagination={false}
					/>
				</div>
			) : null;
		});
	};

	// Navigation function
	const navigateToUser = () => {
		navigate("/forms");
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">FORM MANAGEMENT</h3>
					<p className="page-sub-title">View Form Details</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<div>
						<Button
							icon="pi pi-arrow-left"
							severity="secondary"
							onClick={navigateToUser}
							style={{ borderRadius: "5px", height: "47px" }}
						>
							<span style={{ marginLeft: "5px" }}>Return to Forms</span>
						</Button>
					</div>
				</Box>
			</Box>
			<Box>
				<Table
					columns={verticalColumns}
					dataSource={transformedData}
					rowKey="key"
					pagination={false}
					loading={loading}
					scroll={{ x: "max-content" }}
				/>
			</Box>

			<Box style={{marginTop:"20px"}}>{renderTables()}</Box>

			<Card style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}>
				<h5 style={{ color: userData[0]?.special_note ? "black" : "gray" }}>
					Special Note:
				</h5>
				{userData[0]?.special_note ? (
					<p>{userData[0].special_note}</p>
				) : (
					<p>No special note provided.</p>
				)}
			</Card>
		</Box>
	);
};

export default ViewForm;
